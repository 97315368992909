.lead {
  opacity: .75;
}

.nav-item {
  opacity: .8;
}

h1.title {
    font-size: 54px!important;
    line-height: 54px!important;
    font-family: Texta,serif;
    font-weight: 900;
    color: #111;
    opacity: .75;
}

p.subtext {
  font-feature-settings: 'lnum' 1,'frac' 0,'liga' 1,'clig' 1;
  font-weight: 200;
  font-size: 24px;
  line-height: 44px;
  letter-spacing: 0;
  margin-top: 15px;
}

h3,h4,h5 {
  font-family: 'Texta';
}

.block-button {
  display: block;
  width: 200px !important;
  margin: 0 auto;
}

.material-button-special {
  background-color: #ff9c52 !important;
}

.width-940 {
  width: 940px;
}

.max-width-660 {
  max-width: 940px;
}


.mrgn-0-auto {
  margin: 0 auto;
}

.mt-10 {
  margin-top: 50px;
}


.multiselect-single-label-text {
  font-size: 16px;
  color: #111;
  opacity: .75;
  font-family: 'Texta';
}

.fa-icon {
    display: inline-block;
    fill: currentColor;
    overflow: visible;
    vertical-align: -0.125em;
}

.link-button {
  cursor: pointer;
  color: #3490dc;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 20px;
}

.nav-link:hover {
  color: #AAA;
}

.filter-container-1200 {
  min-width: 1200px;
  width: 1200px;
  margin: 0 auto;
}

p {
  font-size: 17px;
  color: #2b2b2b;
  line-height: 35px;
  margin-bottom: 0;
}

p.small {
  font-size: 15px;
}

.small-orange-text {
  font-size: 12px;
  color: #c0730b;
}


.fw-750 {
  width: 750px;
  margin: 0 auto;
}

.w-150 {
	width: 150px;
}

.w-150:hover {
	cursor: pointer;
	background-color: #EEE;
}

.w-150.selected {
	background-color: #EEE;
	color: #CCC;
}

.input-multiselect .multiselect {
  height: 65px;
  color: #CCC;
  font-size: 28px;
}

.border-radius {
  border-radius: 10px;
}

.bg-light {
  background: rgb(244, 247, 250);
}

.page-hero {
  height: 250px;
  color: #FFF;
}

.btn-search {
  width: 160px;
  font-size: 14px;
  line-height: 28px;
  height: 40px;
  font-weight: 500;
  color: #FFF;
  border-radius: 17px;
  background: #c37105 !important;
  display: inline-block;
  border: 1.5px solid transparent;
  letter-spacing: 1px;
  border-radius: 21px;
  transition: all 0.3s ease;
}

.btn-search:hover {
  color: #f9f7f4;
  background-color: #e58c17 !important;
  border-color: #c27613;
}

.light-gray-background {
  background-color: #eee;
}

.ph-blue-gray {
  background-color: #607D8B;
}

.ph-bondi-blue {
  background-color: #0097a7;
}

.ph-pinkish {
  background-color: #c51162;
}

.btn-filter {
    color: #c37105;
    background-color: #fff;
    border-color: #c37105;
}

.btn-filter:hover {
  background-color: rgb(242, 250, 255);
  border-color: #995905;
  color: #995905;
}

.btn-area {
    background-color: rgb(255, 255, 255);
    border-color: rgb(167, 166, 171);
    color: rgb(42, 42, 51);
}

.btn-area.selected {
  background-color: rgb(241, 241, 244);
  border-color: rgb(167, 166, 171);
  color: rgb(42, 42, 51);
}

.btn-area:hover {
  background-color: rgb(241, 241, 244);
  border-color: rgb(167, 166, 171);
  color: rgb(42, 42, 51);
}

.form-select {
  height: 40px;
}

.ol-viewport {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.grid {
  background-color: #eaeaea;
}

@media (min-width: 576px) {
  .grid .container {
      max-width: 540px;
      padding-right: 6px;
      padding-left: 6px;
  } 
}

@media (min-width: 767px) {
  .grid .container {
      max-width: 720px;
      padding-right: 8px;
      padding-left: 8px;
  }

  .justify-content-center-large {
      justify-content: center!important;
  }
}


@media (min-width: 992px) {
  .grid .container {
      max-width: 1200px;
      padding-right: 8px;
      padding-left: 8px;
  }
}

.grid .container {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding-right: 6px;
    padding-left: 6px;
}

.fs-13 {
  font-size: 13px;
}

.fw-bold {
  font-weight: bold;
}

.page-title {
    font-size: 32px;
    color: #c37105;
    text-shadow: none;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

.page-header {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 0 16px 0;
    padding-bottom: 20px;
    background-color: white;
    border-radius: 2px;
    -webkit-box-shadow: 0 1px 1px 1px rgb(127 127 127 / 30%);
    box-shadow: 0 1px 1px 1px rgb(127 127 127 / 30%);
}

.material-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  min-width: 64px;
  width: 110px;
  height: 36px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  background-color: transparent;
  font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  font-size: 14px;
  font-weight: 500;
  line-height: 34px;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  text-decoration: none;
  color: white;
}

a.material-button:hover {
  color: white !important;
}

.material-button-blue {
  background: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
}

.material-button-orange {
  background: radial-gradient(1029px at 0.1% 1.5%, rgba(252, 15, 15, 0.51) 0%, rgba(252, 180, 24, 0.54) 14.7%, rgba(255, 253, 26, 0.53) 29.7%, rgba(85, 255, 18, 0.53) 47.1%, rgba(16, 215, 255, 0.53) 67.8%, rgba(130, 10, 255, 0.51) 86.7%, rgba(255, 22, 181, 0.53) 99.9%);

}

.material-button::-moz-focus-inner {
    border: none;
}

/* Overlay */
.material-button::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: currentColor;
    opacity: 0;
    transition: opacity 0.2s;
}

/* Ripple */
.material-button::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    padding: 50%;
    width: 32px;
    height: 32px;
    background-color: currentColor;
    opacity: 0;
    transform: translate(-50%, -50%) scale(1) ;
    transition: opacity 1s, transform 0.5s;
}

/* Hover, Focus */
.material-button:hover::before {
    opacity: 0.04;
}

.material-button:focus::before {
    opacity: 0.12;
}

.material-button:hover:focus::before {
    opacity: 0.16;
}

/* Active */
.material-button:active::after {
    opacity: 0.16;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0s;
}

/* Disabled */
.material-button:disabled {
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    background-color: transparent;
    cursor: initial;
}

.mb-2 {
  margin-bottom: 10px;
}

.pb-2 {
  padding-bottom: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

.pb-3 {
  padding-bottom: 15px;
}

.text-italics {
  font-style: italic;
}

.fs-12 {
  font-size: 12px;
}


.material-button:disabled::before {
    opacity: 0;
}

.material-button:disabled::after {
    opacity: 0;
}

.btn-outline-primary:hover {
    color: #fff !important;
    background-color: #3490dc;
    border-color: #3490dc;
}

.btn-outline-success:hover {
    color: #fff !important;
    background-color: #38c172;
    border-color: #38c172;
}


.btn-large {
    font-weight: 500;
    font-family: "Graphik Web","Helvetica Neue",Helvetica,Arial,Verdana,sans-serif;
    text-align: center;
    vertical-align: middle;
    text-transform: capitalize;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 18px;
    margin-right: 18px;
    border: 1px solid transparent;
    border-radius: 2px;
    display: inline-block;
    -ms-transition: border 0.2s ease-in-out 0s, box-shadow 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s;
    transition: border 0.2s ease-in-out 0s, box-shadow 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s;
    font-size: 1.6rem;
    height: 48px;
    line-height: 46px;
}

.styl-grn {
    color: #ffffff;
    background-color: #007c89;
    -ms-transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
}

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-form {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 550px;
    height: 650px;
    overflow: hidden;
    margin-top: 30px;
    border-radius: 3px;
  }

  .modal-header,
  .modal-footer {
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    font-size: 20px;
    justify-content: space-between;
  }

  .modal-header h2 {
    color: #241c15;
    font-size: 32px;
    font-family: 'Playfair Display', serif;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
  }

  .modal-body {
    overflow-y: auto;
  }

  .modal-header .btn-close {
    padding: .5rem 1rem;
    font-size: 32px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }

  .multiselect {
  position: relative;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  border: var(--ms-border-width, 1px) solid var(--ms-border-color, #D1D5DB);
  border-radius: var(--ms-radius, 4px);
  background: var(--ms-bg, #FFFFFF);
  font-size: var(--ms-font-size, 1rem);
  min-height: calc(2 * var(--ms-border-width, 1px) + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375) + 2 * var(--ms-py, 0.5rem));

  &.is-open {
    border-radius: var(--ms-radius, 4px) var(--ms-radius, 4px) 0 0;
  }

  &.is-open-top {
    border-radius: 0 0 var(--ms-radius, 4px) var(--ms-radius, 4px);
  }

  &.is-disabled {
    cursor: default;
    background: var(--ms-bg-disabled, #F3F4F6);
  }

  &.is-active {
    box-shadow: 0 0 0 var(--ms-ring-width, 3px) var(--ms-ring-color, #10B98130);
  }
}

.multiselect-multiple-label,
.multiselect-single-label,
.multiselect-placeholder {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  font-size: 12px;
  left: 0;
  top: 0;
  pointer-events: none;
  background: transparent;
  line-height: var(--ms-line-height, 1.375);
  padding-left: var(--ms-px, 0.875rem);
  padding-right: calc(1.25rem + var(--ms-px, 0.875rem) * 3);
  box-sizing: border-box;
  max-width: 100%;
}

.multiselect-placeholder {
  color: var(--ms-placeholder-color, #9CA3AF);
}

.multiselect-single-label-text {
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.multiselect-search {
  width: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  outline: none;
  box-sizing: border-box;
  border: 0;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  background: var(--ms-bg, #FFFFFF);
  border-radius: var(--ms-radius, 4px);
  padding-left: var(--ms-px, 0.875rem);

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }
}

.multiselect-tags {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-wrap: wrap;
  margin: var(--ms-tag-my, 0.25rem) 0 0;
  padding-left: var(--ms-py, 0.5rem);
  align-items: center;
}

.multiselect-tag {
  background: var(--ms-tag-bg, #10B981);
  color: var(--ms-tag-color, #FFFFFF);
  font-size: var(--ms-tag-font-size, 0.875rem);
  line-height: var(--ms-tag-line-height, 1.25rem);
  font-weight: var(--ms-tag-font-weight, 600);
  padding: var(--ms-tag-py, 0.125rem) 0 var(--ms-tag-py, 0.125rem) var(--ms-tag-px, 0.5rem);
  border-radius: var(--ms-tag-radius, 4px);
  margin-right: var(--ms-tag-mx, 0.25rem);
  margin-bottom: var(--ms-tag-my, 0.25rem);
  display: flex;
  align-items: center;
  white-space: nowrap;

  &.is-disabled {
    padding-right: var(--ms-tag-px, 0.5rem);
    background: var(--ms-tag-bg-disabled, #9CA3AF);
    color: var(--ms-tag-color-disabled, #FFFFFF);
  }
}

.multiselect-tag-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--ms-tag-remove-py, 0.25rem) var(--ms-tag-remove-px, 0.25rem);
  margin: var(--ms-tag-remove-my, 0rem) var(--ms-tag-remove-mx, 0.125rem);
  border-radius: var(--ms-tag-remove-radius, 4px);
  
  &:hover {
    background: #00000010;
  }
}

.multiselect-tag-remove-icon {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
  background-color: currentColor;
  opacity: 0.8;
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
}

.multiselect-tags-search-wrapper {
  display: inline-block;
  position: relative;
  margin: 0 var(--ms-tag-mx, 4px) var(--ms-tag-my, 4px);
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
}

.multiselect-tags-search-copy {
  visibility: hidden;
  white-space: pre-wrap;
  display: inline-block;
  height: 1px;
  width: 100%;
}

.multiselect-tags-search {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 0;
  appearance: none;
  outline: none;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  box-sizing: border-box;
  width: 100%;
  appearance: none;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }
}

.multiselect-spinner {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'%3E%3C/path%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'%3E%3C/path%3E%3C/svg%3E");
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
  background-color: var(--ms-spinner-color, #10B981);
  width: 1rem;
  height: 1rem;
  z-index: 10;
  margin: 0 var(--ms-px, 0.875rem) 0 0;
  animation: multiselect-spin 1s linear infinite;
  flex-shrink: 0;
  flex-grow: 0;
}

.multiselect-clear {
  padding: 0 var(--ms-px, 0.875rem) 0 0px;
  position: relative;
  z-index: 10;
  opacity: 1;
  transition: .3s;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;

  &:hover .multiselect-clear-icon {
    background-color: var(--ms-clear-color-hover, #000000);
  }
}

.multiselect-clear-icon {
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
  background-color: var(--ms-clear-color, #999999);
  width: 0.625rem;
  height: 1.125rem;
  display: inline-block;
  transition: .3s;
}

.multiselect-caret {
  transform: rotate(0deg);
  transition: .3s transform;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'%3E%3C/path%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'%3E%3C/path%3E%3C/svg%3E");
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
  background-color: var(--ms-caret-color, #999999);
  width: 0.625rem;
  height: 1.125rem;
  margin: 0 var(--ms-px, 0.875rem) 0 0;
  position: relative;
  z-index: 10;
  flex-shrink: 0;
  flex-grow: 0;
  pointer-events: none;

  &.is-open {
    transform: rotate(180deg);
    pointer-events: auto;
  }
}

.multiselect-dropdown {
  position: absolute;
  left: calc(var(--ms-border-width, 1px) * -1);
  right: calc(var(--ms-border-width, 1px) * -1);
  bottom: 0;
  transform: translateY(100%);
  border: var(--ms-dropdown-border-width, 1px) solid var(--ms-dropdown-border-color, #D1D5DB);
  margin-top: calc(var(--ms-border-width, 1px) * -1);
  max-height: 15rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 100;
  background: var(--ms-dropdown-bg, #FFFFFF);
  display: flex;
  flex-direction: column;
  border-radius: 0 0 var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px);
  outline: none;

  &.is-top {
    transform: translateY(-100%);
    top: var(--ms-border-width, 1px);
    bottom: auto;
    flex-direction: column-reverse;
    border-radius: var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px) 0 0;
  }

  &.is-hidden {
    display: none;
  }
}

.multiselect-options {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  max-height: var(--ms-max-height, 10rem);

  &.is-top {
    flex-direction: column-reverse;
  }
}

.multiselect-group {
  padding: 0;
  margin: 0;
}

.multiselect-group-label {
  padding: var(--ms-group-label-py, 0.3rem) var(--ms-group-label-px, 0.75rem);
  font-size: 0.875rem;
  font-weight: 600;
  background: var(--ms-group-label-bg, #E5E7EB);
  color: var(--ms-group-label-color, #374151);
  cursor: default;
  line-height: var(--ms-group-label-line-height, 1.375);
  display: flex;
  box-sizing: border-box;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
  text-align: left;

  &.is-pointable {
    cursor: pointer;
  }

  &.is-pointed {
    background: var(--ms-group-label-bg-pointed, #D1D5DB);
    color: var(--ms-group-label-color-pointed, #374151);
  }

  &.is-selected {
    background: var(--ms-group-label-bg-selected, #059669);
    color: var(--ms-group-label-color-selected, #FFFFFF);
  }

  &.is-disabled {
    background: var(--ms-group-label-bg-disabled, #F3F4F6);
    color: var(--ms-group-label-color-disabled, #D1D5DB);
    cursor: not-allowed;
  }

  &.is-selected.is-pointed {
    background: var(--ms-group-label-bg-selected-pointed, #0c9e70);
    color: var(--ms-group-label-color-selected-pointed, #FFFFFF);
  }

  &.is-selected.is-disabled {
    background: var(--ms-group-label-bg-selected-disabled, #75cfb1);
    color: var(--ms-group-label-color-selected-disabled, #D1FAE5);
  }
}

.multiselect-group-options {
  padding: 0;
  margin: 0;
}

.multiselect-option {
  padding: var(--ms-option-py, 0.5rem) var(--ms-option-px, 0.75rem);
  font-size: var(--ms-option-font-size, 1rem);
  line-height: var(--ms-option-line-height, 1.375);
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  color: #333;

  &.is-pointed {
    background: var(--ms-option-bg-pointed, #F3F4F6);
    color: var(--ms-option-color-pointed, #1F2937);
  }

  &.is-selected {
    background: var(--ms-option-bg-selected, #10B981);
    color: var(--ms-option-color-selected, #FFFFFF);
  }

  &.is-disabled {
    background: var(--ms-option-bg-disabled, #FFFFFF);
    color: var(--ms-option-color-disabled, #D1D5DB);
    cursor: not-allowed;
  }

  &.is-selected.is-pointed {
    background: var(--ms-option-bg-selected-pointed, #26c08e);
    color: var(--ms-option-color-selected-pointed, #FFFFFF);
  }

  &.is-selected.is-disabled {
    background: var(--ms-option-bg-selected-disabled, #87dcc0);
    color: var(--ms-option-color-selected-disabled, #D1FAE5);
  }
}

.multiselect-no-options,
.multiselect-no-results {
  padding: var(--ms-option-py, 0.5rem) var(--ms-option-px, 0.75rem);
  color: var(--ms-empty-color, #4B5563);
}

.multiselect-fake-input {
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  width: 100%;
  height: 1px;
  border: 0;
  padding: 0;
  font-size: 0;
  outline: none;

  &:active, &:focus {
    outline: none;
  }
}

.multiselect-spacer {
  display: none;
}

@keyframes multiselect-spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn:hover {
  color: #212529;
}

.large-input .multiselect-placeholder {
  font-size: 24px;
}

.large-input .multiselect-single-label {
  font-size: 24px;
}

.home-nav-link {
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  margin: 20px;
  padding: 10px;
  filter: drop-shadow(0 0 5px #000);
  text-align: center;
  width: 250px;
  margin: 40px auto;
}

.home-nav-link h4 {
    color: #bf360c;
    font-size: 16px;
    line-height: 32px;
}

.animate-hover:hover {
    /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 1s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

.home-nav-link:hover {
    /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 1s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}


@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

$button-bg: #ff9c52;

.bubbly-button{
  font-family: 'Helvetica', 'Arial', sans-serif;
  display: inline-block;
  font-size: 1em;
  padding: 1em 2em;
  margin-top: 100px;
  margin-bottom: 60px;
  -webkit-appearance: none;
  appearance: none;
  background-color: orange;
  color: #fff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  
  &:focus {
    outline: 0;
  }
  
  &:before, &:after{
    position: absolute;
    content: '';
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }
  
  &:before{
    display: none;
    top: -75%;
    background-image:  
      radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle,  transparent 20%, $button-bg 20%, transparent 30%),
    radial-gradient(circle, $button-bg 20%, transparent 20%), 
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle,  transparent 10%, $button-bg 15%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
  //background-position: 0% 80%, -5% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 85% 30%;
  }
  
  &:after{
    display: none;
    bottom: -75%;
    background-image:  
    radial-gradient(circle, $button-bg 20%, transparent 20%), 
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle,  transparent 10%, $button-bg 15%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
  //background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
 
  &:active{
    transform: scale(0.9);
    background-color: darken(#ff9c52, 5%);
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
  }
  
  &.animate{
    &:before{
      display: block;
      animation: topBubbles ease-in-out 0.75s forwards;
    }
    &:after{
      display: block;
      animation: bottomBubbles ease-in-out 0.75s forwards;
    }
  }
}


@keyframes topBubbles {
  0%{
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
    50% {
      background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;}
 100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
  background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}

@keyframes bottomBubbles {
  0%{
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;}
 100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
  background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}

.school-name {
  font-size: 14px;
  font-weight: bold;
  color: #111;
  max-width:230px;
}

.small-address-text {
  font-size: 12px;
}
