@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Texta';
    src: url('/fonts/TextaRegular.ttf');
}

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

$themeColor: #3498db;

//Vue Slider
@import '~vue-slider-component/lib/theme/default.scss';

// Variables
@import 'zippy';
